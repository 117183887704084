import React, { useContext } from 'react';
import Calendar from 'react-calendar'

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType } from "../../../contexts/data";

import Alert from '../../../components/Alert';
import Svg from '../../../components/Svg';


type DateAndTimeDaySelectorProps = {
  showTimeAlert: boolean;
  showDateUnavailable: boolean;
  onClickDay: (value: Date) => void;
  daySelected: string | undefined;
  unavailableDays: string[];
};

const DateAndTimeDaySelector = ({ showTimeAlert, showDateUnavailable, onClickDay, daySelected, unavailableDays }: DateAndTimeDaySelectorProps) => {

  const {
    bookingData: [bookingData],
    isEditMode,
  } = useContext(DataContext) as DataContextValueType;

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 2);

    return date;
  }

  const getFormatedDaySelected = () => {
    if (daySelected) {      
      const splitedDate = daySelected.split('-');

      const month = parseInt(splitedDate[1], 10) - 1; // Months are 0-based (0 = January)
      const day = parseInt(splitedDate[2], 10);
      const year = parseInt(splitedDate[0], 10);

      const d = new Date(year, month, day).toLocaleDateString('en-US');

      return d;
    }

    return '';
  }

  return (
    <>
      <img className="date-and-time__confetti" src="/images/confetti.png" width={161} height={180} alt="confetti" />
      <h2 className="date-and-time__title">Select a date</h2>

      <Alert className="date-and-time__alert">
        Book your party any day between Monday through Thursday and get 10% off.
      </Alert>

      {showTimeAlert && (
        <Alert className="date-and-time__alert" type="error">
          You’ve jumped too far! To reserve your event give our Guest Service team a call at {bookingData.extra.parkPhoneNumber}.
        </Alert>
      )}

      {showDateUnavailable && (
        <Alert className="date-and-time__alert" type="error">
          No inventory is available for corresponding date
        </Alert>
      )}

      <div className="date-and-time__calendar-container">
        <Calendar
          locale="en-US"
          formatShortWeekday={(_, date) => date.toString().substring(0, 1)}
          defaultActiveStartDate={isEditMode ? undefined : getMinDate()}
          calendarType="hebrew"
          prevLabel={<Svg content="chevron-left" />}
          nextLabel={<Svg content="chevron-right" />}
          minDetail="month"
          onClickDay={onClickDay}
          minDate={getMinDate()}
          value={getFormatedDaySelected()}
          tileDisabled={({ date }) => unavailableDays.some((day) => day === date.toLocaleDateString('en-CA'))}
        />
      </div>
    </>
  );
};

export default DateAndTimeDaySelector;
