import type { Step } from '../contexts/steps';

export const steps: Step[] = [
  {
    buttonLabel: 'Choose Package',
    value: 'choose-package',
    active: false,
    done: true
  },
  {
    buttonLabel: 'Choose Experience',
    value: 'choose-experience',
    active: false,
    done: true
  },
  {
    buttonLabel: 'Enter Guest Details',
    heading: 'ENTER GUEST DETAILS',
    value: 'enter-guest-details',
    active: true,
    done: false
  },
  {
    buttonLabel: 'Select Date & Time',
    heading: 'SELECT DATE AND TIME',
    value: 'select-date-time',
    active: false,
    done: false
  },
  {
    buttonLabel: 'Select Add-ons',
    heading: 'INCLUDED ITEMS & ADD-ONS',
    value: 'select-addons',
    active: false,
    done: false
  },
  {
    buttonLabel: 'Review Order',
    heading: 'REVIEW YOUR ORDER',
    value: 'review-order',
    active: false,
    done: false
  },
  {
    buttonLabel: 'Checkout',
    heading: 'CHECKOUT',
    value: 'checkout',
    active: false,
    done: false
  }
];
