import React, { useEffect } from 'react';
import type { MouseEvent } from "react";

function Footer() {
  type LegalItem = {
    label: string;
    href: string;
    id?: string;
  };

  const legal: LegalItem[] = [
    {
      label: 'Privacy Policy',
      href: 'https://www.skyzone.com/privacy-policy'
    },
    {
      label: 'Terms of Service',
      href: 'https://www.skyzone.com/terms-of-service'
    },
    {
      label: 'Your Privacy Rights',
      href: 'https://submit-irm.trustarc.com/services/validation/d982508e-c2b1-4850-805c-eb33c9438c81'
    },
    {
      label: 'Website Accessibility',
      href: 'https://www.skyzone.com/website-accesibility'
    },
    {
      label: 'Cookie Preferences',
      href: '',
      id: 'cookie-preferences'
    }
  ];

  const getYear = () => {
    return new Date().getFullYear();
  };

  const loadScript = (src: string, isAsync = false) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = isAsync;
    document.head.appendChild(script);
  };

  useEffect(() => {
    const cookiePreferences = document.getElementById('cookie-preferences');
    if (cookiePreferences) {
      cookiePreferences.addEventListener('click', e => {
        e.preventDefault();
        const cookiePreferencesButton = document.querySelector(
          '.osano-cm-widget'
        ) as HTMLButtonElement;
        if (cookiePreferencesButton) {
          cookiePreferencesButton.click();
        }
      });
    }

    if (!window.osanoLoaded) {
      window.osanoLoaded = true;
      // setting the script based on the environment
      if (process.env.NODE_ENV === 'production') {
        loadScript('https://cmp.osano.com/6oq2ASMZUg5g1p9o/2a3b87f7-04fd-4ce5-a565-cb3bad6c5e4d/osano.js');
      } else {
        loadScript('https://cmp.osano.com/6oq2ASMZUg5g1p9o/bddc5413-9b7b-44f5-8f2a-3ab34d9edb8c/osano.js');
      }
    }
  }, []);

  const handleExitBooking = (ev: MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  }

  return (
    <footer className="footer">
      <div className="footer__top">
        <a href="https://www.skyzone.com/parties/" onClick={handleExitBooking}>
          <picture className="footer__logo">
            <img
              src="/images/logo-skyzone.svg"
              width="285"
              height="39"
              alt="Skyzone"
              loading="lazy"
            />
          </picture>
        </a>
      </div>
      <div className="footer__bottom">
        <span className="footer__copyright">© Copyright {getYear()} Sky Zone, LLC</span>

        <div className="footer__legal">
          {legal.map((item, index) => (
            <React.Fragment key={item.label}>
              <a target="_blank" id={item.id} href={item.href}>
                {item.label}
              </a>
              {index < legal.length - 1 && <span>|</span>}
            </React.Fragment>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
