import React, { useContext } from "react";
import type { ChangeEvent } from "react";

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType, Session } from "../../../contexts/data";

import Alert from '../../../components/Alert';
import RadioButton from '../../../components/RadioButton';

type TimeSelectorProps = {
  daySelected: string | undefined;
  timeSelected: string | undefined;
  availableSessions: Session[];
  handleSelectTime: (ev: ChangeEvent<HTMLInputElement>, session: Session) => void;
  isOverVenueLimit: boolean;
};

const TimeSelector = ({ daySelected, availableSessions, timeSelected, handleSelectTime, isOverVenueLimit }: TimeSelectorProps) => {

  const {
    bookingData: [bookingData],
  } = useContext(DataContext) as DataContextValueType;

  const checkPeriod = (session: Session): string => {
    const afternoon = new Date(`${session.date} 12:00`);
    const evening = new Date(`${session.date} 16:31`);
    const date = new Date(`${session.date} ${session.startTime}`);
    
    if (date < afternoon) {
      return 'morning';
    }

    if (date < evening) {
      return 'afternoon'
    }

    return 'evening';
  }

  const getFullDateFormated = () => {
    if (!daySelected || !timeSelected) return;

    const splitedDate = daySelected.split('-');
    const splitedTime = timeSelected.split(':');

    const month = parseInt(splitedDate[1], 10) - 1; // Months are 0-based (0 = January)
    const day = parseInt(splitedDate[2], 10);
    const year = parseInt(splitedDate[0], 10);
    const hours = parseInt(splitedTime[0], 10);
    const minutes = parseInt(splitedTime[1], 10);

    const d = new Date(year, month, day, hours, minutes);

    return d.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  return (
    <>
      <img className="date-and-time__confetti-2" src="/images/confetti.png" width={161} height={180} alt="confetti" />

      <h2 className="date-and-time__title">Select a time</h2>
      <p className="date-and-time__description">We ask that you arrive 30 minutes prior to your scheduled time.</p>

      <form className="date-and-time__form" id="select-date-time">
        {!!availableSessions.filter((session: Session) => checkPeriod(session) === 'morning').length && (
          <div className="date-and-time__times-container">
            <h6 className="date-and-time__period">Morning</h6>
            <div className="date-and-time__radio-group">
              {availableSessions.map((session: Session) => checkPeriod(session) === 'morning' && (
                <RadioButton key={session.startTime} label={session.name} checked={session.startTime === timeSelected} name="startTime" value={session.startTime} onChange={(ev) => handleSelectTime(ev, session)} />
              ))}
            </div>
          </div>
        )}

        {!!availableSessions.filter((session: Session) => checkPeriod(session) === 'afternoon').length && (
          <div className="date-and-time__times-container">
            <h6 className="date-and-time__period">Afternoon</h6>
            <div className="date-and-time__radio-group">
              {availableSessions.map((session: Session) => checkPeriod(session) === 'afternoon' && (
                <RadioButton key={session.startTime} label={session.name} checked={session.startTime === timeSelected} name="startTime" value={session.startTime} onChange={(ev) => handleSelectTime(ev, session)} />
              ))}
            </div>
          </div>
        )}

        {!!availableSessions.filter((session: Session) => checkPeriod(session) === 'evening').length && (
          <div className="date-and-time__times-container">
            <h6 className="date-and-time__period">Evening</h6>
            <div className="date-and-time__radio-group">
              {availableSessions.map((session: Session) => checkPeriod(session) === 'evening' && (
                <RadioButton key={session.startTime} label={session.name} checked={session.startTime === timeSelected} name="startTime" value={session.startTime} onChange={(ev) => handleSelectTime(ev, session)} />
              ))}
            </div>
          </div>
        )}
      </form>

      {isOverVenueLimit && timeSelected && daySelected && (
        <Alert type="error">
          We like how you party! But your guest list has exceeded our party space capacity. Either select different date/time, adjust your counts down or give us a call at {bookingData.extra.parkPhoneNumber} to make sure we reserve a large enough space for your group!
        </Alert>
      )}

      {!isOverVenueLimit && timeSelected && daySelected && (
        <Alert type="info">
          Please review your selection before continuing: <strong>{getFullDateFormated()}</strong>
        </Alert>
      )}
    </>
  );
};

export default TimeSelector;
