import React, { useEffect } from 'react';
import router from './router';
import StepsProvider from './contexts/steps';
import ModalProvider from './contexts/modal';
import DataProvider from './contexts/data';

import { RouterProvider } from 'react-router-dom';

const App = () => {
  useEffect(() => {
    const handlePageShow = (
      event:
        | PageTransitionEvent
        | {
            type: string;
            persisted: boolean;
          }
    ) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  return (
    <ModalProvider>
      <StepsProvider>
        <DataProvider>
          <RouterProvider router={router} />
        </DataProvider>
      </StepsProvider>
    </ModalProvider>
  );
};

export default App;
