import React, { useContext } from 'react';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

function ContactRow() {
  const {
    bookingData: [bookingData]
  } = useContext(DataContext) as DataContextValueType;

  const handleChatClick = () => {
    brandembassy('openChatWindow');
  };

  return (
    <section className="contact-row" id="contact-help">
      <div className="container">
        <h2 className="contact-row__title">Need help with your reservation?</h2>
        <div className="contact-row__links-container">
          <button
            className="contact-row__link"
            role="button"
            aria-label={`Click to chat with us`}
            onClick={handleChatClick}
          >
            <div className="contact-row__info">
              <img
                className="contact-row__icon contact-row__icon--chat"
                src="/icons/messages-question.svg"
                alt="chat"
                width="22"
                height="32"
              />
              <div className="contact-row__number-container">
                <span className="contact-row__number-container__text">Chat</span>
              </div>
            </div>
          </button>
          {bookingData.extra.guestServicesTextNumber && (
            <a
              className="contact-row__link"
              href={`sms:${bookingData.extra.guestServicesTextNumber}`}
              role="button"
              aria-label={`Click to call us at ${bookingData.extra.guestServicesTextNumber}`}
            >
              <div className="contact-row__info">
                <img
                  className="contact-row__icon contact-row__icon--text"
                  src="/icons/messages-sms.svg"
                  alt="text"
                  width="22"
                  height="32"
                />
                <div className="contact-row__number-container">
                  <span className="contact-row__number-container__text">Text</span>
                  <span className="contact-row__number-container__number">
                    {bookingData.extra.guestServicesTextNumber}
                  </span>
                  <span className="contact-row__number-container__disclaimer">
                    * Data + Text fees may apply
                  </span>
                </div>
              </div>
            </a>
          )}
          {bookingData.extra.parkPhoneNumber && (
            <a
              className="contact-row__link"
              href={`tel:${bookingData.extra.parkPhoneNumber}`}
              role="button"
              aria-label={`Click to call us at ${bookingData.extra.parkPhoneNumber}`}
            >
              <div className="contact-row__info">
                <img
                  className="contact-row__icon contact-row__icon--phone"
                  src="/icons/phone.svg"
                  alt="phone"
                  width="22"
                  height="32"
                />
                <div className="contact-row__number-container">
                  <span className="contact-row__number-container__text">Call</span>
                  <span className="contact-row__number-container__number">
                    {bookingData.extra.parkPhoneNumber}
                  </span>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </section>
  );
}

export default ContactRow;
